import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Breadcrumb, Col, DatePicker, Descriptions, Drawer, InputNumber, List, Modal, Row, Space, Statistic, Switch, Table, Tag} from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, Card } from 'antd';

import {
  LikeOutlined,
  ArrowUpOutlined,
  FallOutlined,
  BoldOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MasterSelect } from '../../common/MasterSelect';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';





export const TicketLivePurchase = () => {
  const {  productid } = useParams<{  productid?: string }>();
  const navigate = useNavigate();
  const location = useLocation();


// get the active browser url
  const vendorid = location.pathname.replace('/home/ticketinventory/livepurchase/', '').startsWith('atthetop') ? 'atthetop' : 'parmar';

  const handleVendorChange = (newVendor: string) => {
    navigate(`/home/ticketinventory/livepurchase/${newVendor}`);
  };
return  (

       <>
       <div style={{display: 'none'}}><MasterSelect type='Trip Ticket Inventory' inlineSize={350} /></div>
      <Card title={`Ticket Purchase System(${vendorid})`} extra={
        <> <Select placeholder="Select Vendor" value={vendorid || 'parmar'} style={{ width: 200 }} onChange={handleVendorChange} >
        <Select.Option value="atthetop">At the top</Select.Option>
        <Select.Option value="parmar">parmar</Select.Option>
      </Select> 
      </>
      }>
        <Outlet />
        
      </Card>   
  </>);
}





