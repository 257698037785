import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ThunkHttpClient } from '../../utils/httpThunkClient'
import { stat } from 'fs'
import { popMessage } from '../../../App'
import moment from 'moment'

export const atthetopGetProducts = ThunkHttpClient.post('ticketinventory/purchase/atthetopGetProducts',
    ({}) => ({url: "gateway/AtTheTop/Booking/GetProducts", body: {}}))

export const atthetopGetTimeSlotsWithRates = ThunkHttpClient.post('ticketinventory/purchase/atthetopGetTimeSlotsWithRates',
    (data) => ({url: "gateway/AtTheTop/Booking/GetTimeSlotsWithRates", body: data}))


export const atthetopGetTicketTypes = ThunkHttpClient.post('ticketinventory/purchase/atthetopGetTicketTypes',
    (data) => ({url: "gateway/AtTheTop/Booking/GetTicketTypes", body: data}))


export const atthetopReserveTicket = ThunkHttpClient.post('ticketinventory/purchase/atthetopSaveTicket',
(data) => ({url: "gateway/AtTheTop/Booking/SaveTicket", body: data}))

export const atthetopGetAgentDetails = ThunkHttpClient.post('ticketinventory/purchase/atthetopGetAgentDetails',
() => ({url: "gateway/AtTheTop/Agent/GetAgentDetails", body: {}}))

export const atthetopConfirmTicket = ThunkHttpClient.post('ticketinventory/purchase/atthetopConfirmTicket',
(data) => ({url: "gateway/AtTheTop/Booking/ConfirmTicket", body: data}))

export const parmarGetProducts = ThunkHttpClient.post('ticketinventory/purchase/parmarGetProducts',
    ({}) => ({url: "gateway/parmar/getAttractionList", body: {}}))

export const parmarGetTimeSlotsWithRates = ThunkHttpClient.post('ticketinventory/purchase/parmarGetTimeSlotsWithRates',
    (data) => ({url: "gateway/parmar/getTimeSlotList", body: data}))


export const parmarGetTicketTypes = ThunkHttpClient.post('ticketinventory/purchase/parmarGetTicketTypes',
    (data) => ({url: "gateway/parmar/getPriceForTicketType", body: data}))


export const parmarReserveTicket = ThunkHttpClient.post('ticketinventory/purchase/parmarSaveTicket',
(data) => ({url: "gateway/parmar/saveBooking", body: data}))

export const parmarGetAgentDetails = ThunkHttpClient.post('ticketinventory/purchase/parmarGetAgentDetails',
() => ({url: "gateway/parmar/getAgentDetails", body: {}}))

export const parmarConfirmTicket = ThunkHttpClient.post('ticketinventory/purchase/parmarConfirmTicket',
(data) => ({url: "gateway/parmar/confirmBooking", body: data, headers: {'booking-id': data.bookingId}}))




// Define the initial state using that type
const initialState: any = {
    
}

export const livepurchaseSlice = createSlice({
    name: 'livepurchase',
    initialState,
    reducers: {

        updateStateOfPurchaseLiveSlice: (state, action) => {
            state[action.payload.key] = action.payload.data
            return state;
        }
    },
    extraReducers: (builder) => {
        
        builder.addCase(atthetopGetAgentDetails.fulfilled, (state, action) => {
            state.atthetopgetagentloading = false;
            state.atthetopgetagent = action.payload.data;
        })

        builder.addCase(atthetopGetAgentDetails.pending, (state, action) => {
            state.atthetopgetagentloading =  true;
        })
        builder.addCase(atthetopGetAgentDetails.rejected, (state, action) => {
            state.atthetopgetagentloading = false;
        })

        builder.addCase(atthetopGetProducts.fulfilled, (state, action) => {
            state.atthetopproductlistloading = false;
            state.atthetopproductlist = action.payload.data;
            state.atthetopgettimeslotticket={}
        })

        builder.addCase(atthetopGetProducts.pending, (state, action) => {
            state.atthetopproductlistloading =  true;
            state.atthetopproductlist = {}
        })
        builder.addCase(atthetopGetProducts.rejected, (state, action) => {
            state.atthetopproductlistloading = false;
        })

        builder.addCase(atthetopGetTimeSlotsWithRates.fulfilled, (state, action) => {
            state.atthetopgettimeslotticketloading = false;
            state.atthetopgettimeslotticket = action.payload.data;
            state.atthetopgettimeslotticketarg = action.meta.arg;
        })

        builder.addCase(atthetopGetTimeSlotsWithRates.pending, (state, action) => {
            state.atthetopgettimeslotticketloading =  true;
        })
        builder.addCase(atthetopGetTimeSlotsWithRates.rejected, (state, action) => {
            state.atthetopgettimeslotticketloading = false;
        })

        builder.addCase(atthetopGetTicketTypes.fulfilled, (state, action) => {
            state.atthetopgettickettypeloading = false;
            state.atthetopgettickettype = action.payload.data;
            state.atthetopgetticketarg = action.meta.arg
        })

        builder.addCase(atthetopGetTicketTypes.pending, (state, action) => {
            state.atthetopgettickettypeloading =  action.meta.arg;
        })
        builder.addCase(atthetopGetTicketTypes.rejected, (state, action) => {
            state.atthetopgettickettypeloading = false;
        })


        builder.addCase(atthetopReserveTicket.fulfilled, (state, action) => {
            state.atthetopreserveticketloading = false;
            state.atthetopreserveticket = action.payload.data;
        })

        builder.addCase(atthetopReserveTicket.pending, (state, action) => {
            state.atthetopreserveticketloading =  true;
        })
        builder.addCase(atthetopReserveTicket.rejected, (state, action) => {
            state.atthetopreserveticketloading = false;
        })

       
        
        
        builder.addCase(parmarGetAgentDetails.fulfilled, (state, action) => {
            state.parmargetagentloading = false;
            state.parmargetagent = action.payload.data;
        })

        builder.addCase(parmarGetAgentDetails.pending, (state, action) => {
            state.parmargetagentloading =  true;
        })
        builder.addCase(parmarGetAgentDetails.rejected, (state, action) => {
            state.parmargetagentloading = false;
        })

        builder.addCase(parmarGetProducts.fulfilled, (state, action) => {
            state.parmarproductlistloading = false;
            state.parmarproductlist = action.payload.data;
            state.parmargettimeslotticket={}
        })

        builder.addCase(parmarGetProducts.pending, (state, action) => {
            state.parmarproductlistloading =  true;
            state.parmarproductlist = {}
            state.parmargettimeslotticket = {}
        })
        builder.addCase(parmarGetProducts.rejected, (state, action) => {
            state.parmarproductlistloading = false;
        })

        builder.addCase(parmarGetTimeSlotsWithRates.fulfilled, (state, action) => {
            state.parmargettimeslotticketloading = false;
            state.parmargettimeslotticket = action.payload.data;
        })

        builder.addCase(parmarGetTimeSlotsWithRates.pending, (state, action) => {
            state.parmargettimeslotticketloading =  true;
            state.parmargettimeslotticketarg = action.meta.arg
            state.parmargettickettypeloading = false
        })
        builder.addCase(parmarGetTimeSlotsWithRates.rejected, (state, action) => {
            state.parmargettimeslotticketloading = false;
        })

        builder.addCase(parmarReserveTicket.fulfilled, (state, action) => {
            state.parmarreserveticketloading = false;
            state.parmarreserveticket = action.payload.data;
        })

        builder.addCase(parmarReserveTicket.pending, (state, action) => {
            state.parmarreserveticketloading =  true;
        })
        builder.addCase(parmarReserveTicket.rejected, (state, action) => {
            state.parmarreserveticketloading = false;
        })

        builder.addCase(parmarGetTicketTypes.fulfilled, (state, action) => {
            state.parmargettickettypeloading = false;
            state.parmargettickettype = action.payload.data;
            state.parmargetticketarg = action.meta.arg
        })

        builder.addCase(parmarGetTicketTypes.pending, (state, action) => {
            state.parmargettickettypeloading =  action.meta.arg;
        })
        builder.addCase(parmarGetTicketTypes.rejected, (state, action) => {
            state.parmargettickettypeloading = false;
        })

        builder.addCase(atthetopConfirmTicket.fulfilled, (state, action) => {
            state.atthetopConfirmTicketloading = false;
        })

        builder.addCase(atthetopConfirmTicket.pending, (state, action) => {
            state.atthetopConfirmTicketloading =  true;
        })
        builder.addCase(atthetopConfirmTicket.rejected, (state, action) => {
            state.atthetopConfirmTicketloading = false;
        })

        
        
        
        
    },
})


export const {  updateStateOfPurchaseLiveSlice } = livepurchaseSlice.actions
export default livepurchaseSlice.reducer