import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Breadcrumb, Col, DatePicker, Descriptions, Drawer, InputNumber, List, Modal, Radio, Row, Space, Statistic, Switch, Table, Tag} from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, Card } from 'antd';

import {
  LikeOutlined,
  ArrowUpOutlined,
  FallOutlined,
  BoldOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MasterSelect } from '../../common/MasterSelect';
import { atthetopConfirmTicket, atthetopGetAgentDetails, atthetopGetProducts, atthetopGetTicketTypes, atthetopGetTimeSlotsWithRates, atthetopReserveTicket, updateStateOfPurchaseLiveSlice } 
from './slice-livepurchase';
import { Outlet, useNavigate, useParams } from 'react-router-dom';




const TicketDetails = ({onClose, data}:any) => {
  const dispatch = useDispatch();
  const { atthetopreserveticketloading, atthetopgetticketarg, atthetopConfirmTicketloading, atthetopgettimeslotticketarg } = useSelector((state: any) => state.livepurchase);

  const onFinish = (value:any)=>{
    if(!atthetopgetticketarg.internalProductId){
      Modal.error({
        title: 'Error',
        content: 'Error no internalProductId found, Contact the admin if you need help or try again'
      });
      return;
    }
   const ob = {
      "GuestName": value.GuestName,
      "GuestEmail": value.GuestEmail,
      "GuestMobile": value.GuestMobile ,
      "TimeSlot": atthetopgetticketarg.TimeSlot,
      "Tickets": value.Tickets
}
const childextra = {} as any
value.Tickets.forEach((ele:any)=>{
  const type = ele.TicketGroup.toLowerCase() === 'adult'? "adult": "child";
  childextra[type+'Count'] = ele.Quantity;
  
  childextra[type+'Extra'] = btoa(JSON.stringify({"inventoryProductId": atthetopgetticketarg.internalProductId,
"cost": ele.TicketPrice,
"type": type,
isAutoApproved: true,
"expiry": moment(atthetopgetticketarg.TimeSlot.StartDateTime, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY'),
"parser":"LiveTicketDateTimeParser"}))
});
if(!childextra.adultCount || childextra.adultCount <=0){
  Modal.error({
    title: 'Error',
    content: 'Adult ticket count should be greater than 0, Contact the admin if you need help'
  });
  return;
}
   dispatch(atthetopReserveTicket(ob)).then((res:any)=>{
    if(!res.payload.data.ResultStatus || !res.payload.data.ResponseData.BookingId){
      Modal.error({
        title: 'Error',
        content: res.payload.data?.ErrorMessage || 'Error in reserving ticket'
      });
    } else {
    Modal.confirm({
      title: 'Ticket Reserved',
      content: 'Ticket has been reserved successfully Click Ok to configm booking for the confirmation',
      onOk: () => {

        dispatch(atthetopConfirmTicket({
          "BookingId": res.payload.data.ResponseData.BookingId,
          ...childextra,
          "VoucherNumber": 'Eff'+moment().format('YYYYMMDDHHmmss'),
          "IsEticketRequired": false,
          })).then((res1:any)=>{
          if(!res1.payload?.data?.ResultStatus){
            Modal.error({
              title: 'Error',
              content: res1.payload.data?.ResponseData?.ResultMessage || 'Error in confirming ticket'
            });
          } else  {
          Modal.success({
            title: 'Ticket Confirmed',
            content: 'Ticket has been confirmed successfully ,' + res1.payload.data.ResponseData.BookingId + ' is the booking id', 
          });
        }
          dispatch(atthetopGetAgentDetails(''));  
          onClose()
        })
      },
    });
  }
   });
  }


  return <Drawer title={`${moment(atthetopgetticketarg.StartDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}-${moment(atthetopgetticketarg.EndDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')} ${data.ProductName} (${data.AttractionName})`} 
        width={800}  onClose={onClose} open={data || false}>
        {/* <Descriptions bordered title="Product Detail"> 
         <Descriptions.Item label="ProductName">{data.ProductName}</Descriptions.Item>
            <Descriptions.Item label="AttractionName">{data.AttractionName}</Descriptions.Item>
            <Descriptions.Item label="AttractionName">{data.AttractionName}</Descriptions.Item>
        </Descriptions> */}

        <Row gutter={[16, 16]}>
      {data.Tickets.map((item: any) => (
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} key={item.EventID}>
          <Card
          title={`${item.TicketGroup})`} bordered={false}
          >
            <Space direction="vertical"  >
            <div>
                   <span style={{ color: 'red' }}>Price: {item.TicketPrice} AED</span>
                   
                </div>
                <div><span>Quantity: {item.Quantity}</span></div>
              </Space>
          </Card>
        </Col>
      ))}
    </Row>

        {/* {data.Tickets.map((item:any)=> <Descriptions bordered  title={item.TicketGroup + ' Tickets detail'}> 
         <Descriptions.Item label="TicketId">{item.TicketId}</Descriptions.Item>
            <Descriptions.Item label="TicketGroup">{item.TicketGroup}</Descriptions.Item>
            <Descriptions.Item label="TicketTypeCode">{item.TicketTypeCode}</Descriptions.Item>
            <Descriptions.Item label="TicketDescription">{item.TicketDescription}</Descriptions.Item>
            <Descriptions.Item label="TicketPrice">{item.TicketPrice}</Descriptions.Item>
            <Descriptions.Item label="Quantity">{item.Quantity}</Descriptions.Item>
            <Descriptions.Item label="DiscountTier">{item.DiscountTier}</Descriptions.Item>
        </Descriptions>)} */}

        <Card title="Reserve Ticket">
 

        <Form
      name="rserviceticketforliveproduct"
      onFinish={onFinish}
      disabled={atthetopreserveticketloading || atthetopConfirmTicketloading}
      className="login-form"
      
      initialValues={{Tickets: data.Tickets,  GuestName: "Guest"}}
      style={{ maxWidth: 'none', padding: '5px' }}

    > 
     <Form.Item
                label={'Guest Name'}
                name={'GuestName'}
                rules={[{ required: true, message: 'Missing GuestName' }]}
              ><Input placeholder="GuestName" /></Form.Item>
                   <Form.Item
                label={'Guest Email'}
                name={'GuestEmail'}
              ><Input placeholder="GuestEmail" /></Form.Item>
                   <Form.Item
                label={'Guest Mobile'}
                name={'GuestMobile'}
              ><Input placeholder="GuestMobile" /></Form.Item>
              
    <Form.List name="Tickets">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
              <Form.Item
                {...restField}
                label={data.Tickets[name].TicketGroup + ' Ticket Quantity'}
                name={[name, 'Quantity']}
                rules={[{ required: true, message: 'Missing Quantity'}]}
              >
                <InputNumber placeholder="Quantity" />
              </Form.Item>
            </Space>
          ))}
        </>
      )}
    </Form.List>

      <Form.Item>
        <Button type="primary" htmlType="submit" 
        loading={atthetopreserveticketloading}
          className="login-form-button" >
           Reserve Tickets
        </Button>
      </Form.Item>
      {/* <Form.Item >
        <Button  ><ClearOutlined /> Clear Filter</Button>
      </Form.Item> */}
    </Form>
        </Card>
</Drawer>
}


const BalanceNCredit = ({atthetopgetagent, atthetopgetagentloading}: any) => (
  
  <Row gutter={16}>
    <Col span={8}>
      <Card bordered={false}>
       
        <Statistic
          loading={atthetopgetagentloading}
          title="CreditLimit"
          value={atthetopgetagent.CreditLimit}
          precision={2}
          valueStyle={{ color: '#3f8600' }}
          prefix={<ArrowUpOutlined />}
          suffix="AED"
        />
      </Card>
    </Col>
    <Col span={8}>
      <Card bordered={false}>
        <Statistic
          title="Balance"
          value={atthetopgetagent.Balance}
          loading={atthetopgetagentloading}
          precision={2}
          valueStyle={{ color: '#3f8600' }}
          prefix={<BoldOutlined />}
          suffix="AED"
        />
      </Card></Col>
    <Col span={8}>
      <Card bordered={false}>
        <Statistic
          title="Outstanding"
          value={atthetopgetagent.Outstanding}
          loading={atthetopgetagentloading}
          precision={2}
          valueStyle={{ color: '#cf1322' }}
          prefix={<FallOutlined />}
          suffix="AED"
        />
      </Card>
    </Col>
  </Row>
);


const AtTheTopCardSummary = ({ atthetopgettimeslotticketloading, filterData, atthetopgettickettypeloading, productid, handelProductId }: any) => {
  return (
    <Row gutter={[16, 16]}>
      {filterData.map((item: any) => (
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3} key={item.EventID}>
          <Card loading={atthetopgettimeslotticketloading || atthetopgettickettypeloading}  className={item.Available < 50 ? 'hightp1' : 'lowp1'} title={`${moment(item.StartDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}-${moment(item.EndDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')} (${item.Available})`} bordered={false}
          
          onClick={() => handelProductId({
            "TimeSlot": {
              "ProductId": +(productid || 0),
              "EventID": item.EventID,
              "StartDateTime": item.StartDateTime,
              "EndDateTime": item.EndDateTime
            }
          })}
          >
            <Space direction="vertical">
            {item.Prices.filter((p: any) => p.Type === 'Adult' || p.Type === 'Child').map((p: any) => (
                <div>
                  {p.Description} <span style={{ color: 'red' }}>Price: {p.Price} AED</span>
                </div>
              ))}
              </Space>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const AttheTopPurchaseProductDeatil = ({internalProduct}:any) => {
  const { atthetopgettimeslotticketloading, atthetopgettimeslotticket, atthetopgettickettype, atthetopgettickettypeloading, atthetopgetticketarg } = useSelector((state: any) => state.livepurchase);
  const {  productid } = useParams<{ productid?: string }>();
  const dispatch = useDispatch()
  const [internalProductId, setInternalProductId] = useState(undefined);
  const handelProductId = (data:any) => {
    dispatch(atthetopGetTicketTypes({...data, internalProductId:internalProductId})).then((res:any)=>{
      if(!res.payload.data.ResultStatus){
        Modal.error({
          title: 'Error',
          content: res.payload.data?.ResponseData?.ResultMessage || 'Error in getting ticket details'
        });
      }
    });
  };
  
  const data = ((atthetopgettimeslotticket || {}).ResponseData || {}).TimeSlots || []

  const onFormLayoutChange = (value:any)=>{
    console.log('internalProductId', internalProductId)
    dispatch(atthetopGetTimeSlotsWithRates({"ProductId": productid,
    internaldataid: internalProductId,
    "StartDateTime":  moment(value.DateTime.format('YYYY-MM-DD'), 'YYYY-MM-DD').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    "EndDateTime": moment(value.DateTime.format('YYYY-MM-DD'), 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD HH:mm:ss')})) ;
  }

const filtersystemprojct = internalProduct?.find((item:any)=>item.id === internalProductId)


  let filterData = [];
   if(filtersystemprojct){
    const datetimerang = JSON.parse(filtersystemprojct.other).livePurchase.atthetopTime
    if(datetimerang){
      const arratime = datetimerang.split('&').map((e:any)=>e.replace(' ', '')).map((e:any)=>({start: moment(e.split('-')[0],'HH:mm'), end: moment(e.split('-')[1],'HH:mm')}));
      const today = moment()
      
      let i =0;
    filterData = data.filter((item: any) => {
      const startdate = moment(item.StartDateTime, 'YYYY-MM-DD HH:mm:ss').set({
          year: today.year(),
          month: today.month(),
          date: today.date()
      });
      const enddate = moment(item.EndDateTime, 'YYYY-MM-DD HH:mm:ss').set({
        year: today.year(),
        month: today.month(),
        date: today.date()
    });
     i++
      const ti = arratime.find((e:any)=>{
        return startdate.isSameOrAfter(e.start) && enddate.isSameOrBefore(e.end)
      });
      return ti !== undefined;
    })

  } }

  

return  (

       <>
      {atthetopgettickettype && atthetopgettickettype.ResponseData && atthetopgettickettype.ResponseData.Tickets ?
   <TicketDetails data={atthetopgettickettype.ResponseData}  atthetopgetticketarg={atthetopgetticketarg.TimeSlot} onClose={()=>{dispatch(updateStateOfPurchaseLiveSlice({key: 'atthetopgettickettype', data: {}}))}} />: null}
   
   <Form
      name="filterdaterangeticketforproduct"
      layout={'inline'}
      onFinish={onFormLayoutChange}
      className="login-form"
      style={{ maxWidth: 'none', padding: '5px' }}

    > 
    

      <Form.Item name="DateTime" label="DateTime" required>
        <DatePicker placeholder='Date Time' />
      </Form.Item>


      <Form.Item>
        <Button type="primary" htmlType="submit"
          className="login-form-button" loading={atthetopgettimeslotticketloading}>
            <SearchOutlined />
          Search tickets
        </Button>
      </Form.Item>
      {/* <Form.Item >
        <Button  ><ClearOutlined /> Clear Filter</Button>
      </Form.Item> */}
    </Form>
   <Space> Select System Project  <Radio.Group
    value={internalProductId}
    onChange={(e)=>setInternalProductId(e.target.value)}
    options={internalProduct?.map((ee:any)=>({value: ee.id, label: ee.masterValue }))}
  /></Space>

      <AtTheTopCardSummary atthetopgettimeslotticketloading={atthetopgettimeslotticketloading} 
      filterData={filterData}
      atthetopgettickettypeloading={atthetopgettickettypeloading}
      productid={productid}
      handelProductId={handelProductId}
       />
    
   {/* <List
      loading={atthetopgettimeslotticketloading}
    itemLayout="vertical"
    size="large"
    dataSource={filterData}
    renderItem={(item:any) => (
      
      <List.Item
        key={item.title}
      >
          <Descriptions
          bordered
          column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        > 
         <Descriptions.Item label="Reservation"> <Button type='primary' 
         disabled={ atthetopgettickettypeloading && atthetopgettickettypeloading.TimeSlot.EventID !== item.EventID}
        loading={atthetopgettickettypeloading && atthetopgettickettypeloading.TimeSlot.EventID === item.EventID}
          onClick={()=>handelProductId({"TimeSlot": {"ProductId": +(productid || 0),
        "EventID": item.EventID,
        "StartDateTime": item.StartDateTime,
        "EndDateTime": item.EndDateTime}})
        
        }>View Tickets Detail</Button></Descriptions.Item>
       
        <Descriptions.Item label="EventName">{item.EventName}</Descriptions.Item>
        <Descriptions.Item label="StartDateTime">{item.StartDateTime}</Descriptions.Item>
        <Descriptions.Item label="EndDateTime">{item.EndDateTime}</Descriptions.Item>
        <Descriptions.Item label="Available ticket count">{item.Available}</Descriptions.Item>
              {item.Prices.map((p:any)=> <Descriptions.Item label={p.Type}> {p.Description} {" "} <span style={{color: 'red'}}> {" "} Price:  {p.Price} AED</span></Descriptions.Item>)}
          </Descriptions>
      </List.Item>
    )}
  />  */}
  </>);
}



export const AttheTopPurchaseProductList = () => {
  const { atthetopproductlistloading, atthetopproductlist, atthetopgetagent,atthetopgetagentloading } = useSelector((state: any) => state.livepurchase);
  const { masterSelectValuesLoading, masterSelectValues } = useSelector((state: any) => state.master)
  const { vendorid="atthetop", productid } = useParams<{ vendorid?: string; productid?: string }>();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handelProductId = (productId: string) => {
    navigate(`/home/ticketinventory/livepurchase/${vendorid}/${productId || ''}`);
  };

  const productMapping = masterSelectValues['Trip Ticket Inventory']?.reduce((arrg:any, e:any)=>{
    if(e.other) {
      const extra = JSON.parse(e.other);
      if(extra?.livePurchase && extra?.livePurchase?.atthetopProductId){
        if(!arrg[extra.livePurchase.atthetopProductId]) {
          arrg[extra.livePurchase.atthetopProductId]= [];
        }
        arrg[extra.livePurchase.atthetopProductId].push(e);
      }
    }
      return arrg
    }, {}) || {}

  const data = ((atthetopproductlist || {}).ResponseData || []).map((item:any)=>({
    title: `${item.ProductName} (${item.AttractionName})`,
    avatar: item.ProductBanner,
    IsEventBased: item.IsEventBased,
    description: item.ProductDescription,
    AttractionId: item.AttractionId,
    ProductId: item.ProductId,
    productMapping: productMapping[item.ProductId+''] || []
  }))

  data.sort((a:any, b:any)=>{
    if(a.productMapping.length === 0 && b.productMapping.length > 0) return 1;
    if(a.productMapping.length > 0 && b.productMapping.length === 0) return -1;
    return 0;
  })



  useEffect(()=>{
    dispatch(atthetopGetProducts(''));  
    dispatch(atthetopGetAgentDetails('')); 
  },[])

return  (

       <>
       {/* <Breadcrumb
        items={[
      {
        title: 'Product List',
        onClick: () => navigate(`/home/ticketinventory/livepurchase/${vendorid}`)
      },
      {
        title: 'Product Tickets',
      },
    ]} /> */}
    <BalanceNCredit atthetopgetagent={atthetopgetagent?.ResponseData || {}} atthetopgetagentloading={atthetopgetagentloading} />
   
    {productid ? <AttheTopPurchaseProductDeatil internalProduct={productMapping[productid+'']}/> :
      <List
      loading={atthetopproductlistloading}
    itemLayout="vertical"
    size="large"
    dataSource={data}
    renderItem={(item:any) => (
      
      <List.Item
        key={item.title}
        actions={[<Button type='primary' disabled={!productMapping[item.ProductId+'']}  onClick={()=>handelProductId(item.ProductId)}>View Available tickets</Button>]}
        extra={
          <img
            width={172}
            height={100}
            alt="logo"
            src={item.avatar}
          />
        }
      >
        <List.Item.Meta
          avatar={item.IsEventBased? <Badge.Ribbon text="Event Base" color="red"><Avatar shape="square" size="large" /></Badge.Ribbon>:  <Avatar shape="square" size="large" />}
          title={<a href={item.href}>{`(ID: ${item.ProductId})  ${item.title}` }</a>}
        />
        <div dangerouslySetInnerHTML={{__html: item.description}}></div>
      </List.Item>
    )}
  /> }  
  </>);
}