import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Breadcrumb, Col, DatePicker, Descriptions, Drawer, InputNumber, List, Modal, Radio, Row, Space, Statistic, Switch, Table, Tag} from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, Card } from 'antd';

import {
  LikeOutlined,
  ArrowUpOutlined,
  FallOutlined,
  BoldOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MasterSelect } from '../../common/MasterSelect';
import { parmarConfirmTicket, parmarGetAgentDetails, parmarGetProducts, parmarGetTicketTypes, parmarGetTimeSlotsWithRates, parmarReserveTicket,updateStateOfPurchaseLiveSlice } from './slice-livepurchase';
import { Outlet, useNavigate, useParams } from 'react-router-dom';




const TicketDetails = ({onClose, data, parmargetticketevent}:any) => {
  const dispatch = useDispatch();
  const { parmarreserveticketloading, parmargettimeslotticketarg, parmargetticketarg } = useSelector((state: any) => state.livepurchase);

  const onFinish = (value:any)=>{
    if(!parmargetticketarg.internaldataid){
      Modal.error({
        title: 'Error',
        content: 'Error no internalProductId found, Contact the admin if you need help or try again'
      });
      return;
    }
   const ob = {
       ...data,
      ...parmargettimeslotticketarg,
      ...parmargetticketarg,
      nofAdult: value.nofAdult,
      nofChild: value.nofChild,
      }
    
      const childextra = {} as any
      childextra['adultCount'] = value.nofAdult;
      childextra['adultExtra'] = btoa(JSON.stringify({"inventoryProductId": parmargetticketarg.internaldataid,
      "cost": data.adultPrice,
      "type": 'adult',
      "expiry": moment(data.startDateTime, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY'),
      isAutoApproved: true,
      "parser":"LiveTicketDateTimeParser"}))
      childextra['childCount'] = value.nofChild;
      childextra['childExtra'] = btoa(JSON.stringify({"inventoryProductId": parmargetticketarg.internaldataid,
      "cost": data.childPrice,
      "type": 'child',
      isAutoApproved: true,
      "expiry": moment(data.startDateTime, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY'),
      "parser":"LiveTicketDateTimeParser"}))
      
      if(!childextra.adultCount || childextra.adultCount <=0){
        Modal.error({
          title: 'Error',
          content: 'Adult ticket count should be greater than 0, Contact the admin if you need help'
        });
        return;
      }

   dispatch(parmarReserveTicket(ob)).then((res:any)=>{
    if(res.payload.data.bookingId === null || res.payload.data.bookingId === undefined || (res.payload.data.bookingId+"").length <=0 ) {
      Modal.error({
        title: 'Error',
        content: res.payload.data.errorMessage || 'Error occured  while reserving tickets'
      });
    } else {
      Modal.confirm({
        title: 'Ticket Reserved',
        content: 'Ticket has been reserved successfully Click Ok to configm booking for the confirmation',
        onOk: () => {
          dispatch(parmarConfirmTicket({
            "travelDate": ob.bookingDate,
            "attractionId": ob.attractionId,
            "ticketTypeId": ob.ticketTypeId,
            "nofAdult": ob.nofAdult,
            "nofChild": ob.nofChild,
            "bookingId": res.payload.data.bookingId,
            "customerName": value.customerName,
            "customerEmail": value.customerEmail,
            "customerPhoneNumber": value.customerPhoneNumber,
            ...childextra,
            tempReference: res.payload.data.tempReference,
            })).then((res1:any)=>{
              console.log(res1)
              if(res1.payload === undefined || res1.payload?.data === null || res1.payload?.data === undefined ) {
                Modal.error({
                  title: 'Error',
                  content: res.payload?.data?.errorMessage || 'Error occured  while confirm tickets'
                });
              } else {
                Modal.success({
                  title: 'Ticket Confirmed',
                  content: 'Ticket has been confirmed successfully, bookingId: '+res.payload.data.bookingId + ' Booking Reference: '+res1.payload.data.bookingRefNumber
                });
                onClose()
                dispatch(parmarGetAgentDetails(''));
              }
          })
        },
      });

    }
   
   });
  }


  return <Drawer
  
  
  title={`${moment(data.startDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}-${moment(data.endDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')} ${data.ProductName} (${data.AttractionName})`} 
  width={800}  onClose={onClose} open={data || false}>
  {/* <Descriptions bordered title="Product Detail"> 
   <Descriptions.Item label="ProductName">{data.ProductName}</Descriptions.Item>
      <Descriptions.Item label="AttractionName">{data.AttractionName}</Descriptions.Item>
      <Descriptions.Item label="AttractionName">{data.AttractionName}</Descriptions.Item>
  </Descriptions> */}

  <Row gutter={[16, 16]}>
  <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
    <Card
    title={`Adult`} bordered={false}
    >
      <Space direction="vertical">
      <div>
             <span style={{ color: 'red' }}>Price: {data.adultPrice} AED</span>
             
          </div>
          <div><span>Quantity: {data.adultAvailCount}</span></div>
        </Space>
    </Card>
  </Col>
  <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
    <Card
    title={`Child`} bordered={false}
    >
      <Space direction="vertical" >
      <div><span style={{ color: 'red' }}>Price: {data.childPrice} AED</span></div>
          <div><span>Quantity: {data.childAvailCount}</span></div>
        </Space>
    </Card>
  </Col>
  </Row>
{/* </Row>
  
  title="Basic Drawer" width={1000}  onClose={onClose} open={data || false}>
        <Descriptions bordered  title="EventDetail"> 
            <Descriptions.Item label="eventTypeId">{data.eventTypeId}</Descriptions.Item>
            <Descriptions.Item label="eventName">{data.eventName}</Descriptions.Item>
            <Descriptions.Item label="startDateTime">{data.startDateTime}</Descriptions.Item>
            <Descriptions.Item label="endDateTime">{data.endDateTime}</Descriptions.Item>
        </Descriptions>
        <Descriptions bordered  title={' Tickets detail'}> 
        <Descriptions.Item label="adultAvailCount">{data.adultAvailCount}</Descriptions.Item>
            <Descriptions.Item label="adultPrice">{data.adultPrice}</Descriptions.Item>
         <Descriptions.Item label="available">{data.available}</Descriptions.Item>
            <Descriptions.Item label="childAvailCount">{data.childAvailCount}</Descriptions.Item>
            <Descriptions.Item label="childPrice">{data.childPrice}</Descriptions.Item>
            
            
        </Descriptions> */}

        <Card title="Reserve Ticket">
 

        <Form
      name="rserviceticketforliveproduct"
      onFinish={onFinish}
      disabled={parmarreserveticketloading}
      className="login-form"
      initialValues={{ customerName: "Guest"}}
      style={{ maxWidth: 'none', padding: '5px' }}

    > 
     
              <Form.Item
                label={'Guest Name'}
                name={'customerName'}
                rules={[{ required: true, message: 'Missing GuestName' }]}
              ><Input placeholder="GuestName" /></Form.Item>
                   <Form.Item
                label={'Guest Email'}
                name={'customerEmail'}
                rules={[{ required: true, message: 'Missing GuestEmail' }]}
              ><Input placeholder="GuestEmail" /></Form.Item>
                   <Form.Item
                label={'Guest Mobile'}
                name={'customerPhoneNumber'}
                rules={[{ required: true, message: 'Missing GuestMobile' }]}
              ><Input placeholder="GuestMobile" /></Form.Item>

              <Form.Item
                label={'Adult ticket count'}
                name={'nofAdult'}
                required
              ><InputNumber placeholder="Adult ticket count" /></Form.Item>
              <Form.Item
                label={'Child ticket count'}
                name={'nofChild'}
                
              ><InputNumber disabled={data.childPrice<=0} placeholder="Child ticket count" /></Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" 
        loading={parmarreserveticketloading}
          className="login-form-button" >
           Reserve Tickets
        </Button>
      </Form.Item>
      {/* <Form.Item >
        <Button  ><ClearOutlined /> Clear Filter</Button>
      </Form.Item> */}
    </Form>
        </Card>
</Drawer>
}


const BalanceNCredit = ({parmargetagent, parmargetagentloading}: any) => (
  
  <Row gutter={16}>
    <Col span={8}>
      <Card bordered={false}>
        UserName: {parmargetagent.agencyName}
        <Statistic
          loading={parmargetagentloading}
          title="CreditLimit"
          value={parmargetagent.creditLimit}
          precision={2}
          valueStyle={{ color: '#3f8600' }}
          prefix={<ArrowUpOutlined />}
          suffix="AED"
        />
      </Card>
    </Col>
    <Col span={8}>
      <Card bordered={false}>
        AgentId: {parmargetagent.agencyCode}
        <Statistic
          title="Balance"
          value={parmargetagent.currentBalance}
          loading={parmargetagentloading}
          precision={2}
          valueStyle={{ color: '#3f8600' }}
          prefix={<BoldOutlined />}
          suffix="AED"
        />
      </Card></Col>
    <Col span={8}>
      <Card bordered={false}>
        .
        <Statistic
          title="Outstanding"
          value={parmargetagent.creditUtilized}
          loading={parmargetagentloading}
          precision={2}
          valueStyle={{ color: '#cf1322' }}
          prefix={<FallOutlined />}
          suffix="AED"
        />
      </Card>
    </Col>
  </Row>
);


const ParamarCardSummary = ({ parmargettimeslotticketloading, filterData, parmargettickettypeloading, parmargettimeslotticketarg, handelProductId }: any) => {
  return (
    <Row gutter={[16, 16]}>
      {filterData.map((item: any, i:any) => (
        <Col key={i} xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <Card loading={parmargettimeslotticketloading || parmargettickettypeloading}  className={item.Available < 50 ? 'hightp1' : 'lowp1'} title={`${moment(item.startDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}-${moment(item.endDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')} (${item.available})`} bordered={false}
          
          onClick={() =>handelProductId({...parmargettimeslotticketarg,
            "eventTypeId": item.eventTypeID,
            "eventId": item.eventID,
            "status": item.status,
            "available": item.available,
            "resourceId": item.resourceID,
            "eventName": item.eventName,
            "startDateTime": item.startDateTime,
            "endDateTime": item.endDateTime, ...item})}
          >
            <Space direction="vertical">
            <div>
            Adult <span style={{ color: 'red' }}>Price: {item.adultPrice} AED</span>
                </div>
                <div>
                  Child <span style={{ color: 'red' }}>Price: {item.childPrice} AED</span>
                </div>
              </Space>
          </Card>
        </Col>
      ))}
    </Row>
  );
};


const ParmarPurchaseProductDeatil = () => {
  const { parmargettimeslotticketloading, parmargettimeslotticket,parmargettimeslotticketarg, parmargettickettype, parmargettickettypeloading, parmargetticketevent } = useSelector((state: any) => state.livepurchase);
  const {  productid } = useParams<{ productid?: string }>();
  const { masterSelectValuesLoading, masterSelectValues } = useSelector((state: any) => state.master)
  const att = (productid || "").split(',');
  const productMapping = masterSelectValues['Trip Ticket Inventory']?.reduce((arrg:any, e:any)=>{
    if(e.other) {
    const extra = JSON.parse(e.other);
    if(extra?.livePurchase && extra?.livePurchase?.parmarattractionId){
      if(!arrg[extra.livePurchase?.parmarattractionId]) {
        arrg[extra.livePurchase?.parmarattractionId]= [];
      }
      arrg[extra.livePurchase?.parmarattractionId].push(e);
    }
  }
    return arrg
  }, {}) || {}

  const dispatch = useDispatch()
  const [internalProductId, setInternalProductId] = useState(undefined);
  
  const handelProductId = (data:any) => {
    dispatch(parmarGetTicketTypes({...data, internaldataid: internalProductId})).then((res:any)=>{
      if(res.payload === undefined || res.payload?.data.errorCode !== 0) {
        Modal.error({
          title: 'Error',
          content: res.payload?.data.errorMessage || 'Error occured  while getting detail of ticket types'
        });
      }
    });
  };
 
  const data = parmargettimeslotticket || {}
  const onFormLayoutChange = (value:any)=>{
    
    
    
    dispatch(parmarGetTimeSlotsWithRates({"attractionId": +att[0],
    "bookingDate": value.bookingDate.format('YYYY-MM-DD'),
    "internaldataid": internalProductId,
    "ticketTypeId": +att[1]})).then((res:any)=>{
      if(res.payload.data.errorCode !== 0) {
        Modal.error({
          title: 'Error',
          content: res.payload.data.errorMessage || 'Error occured  while getting detail'
        });
      }
      
    });
    
  }

  const internalProduct = productMapping[att[0]+''] || []

  const filtersystemprojct = internalProduct.find((item:any)=>item.id === internalProductId)


  let filterData = [];
   if(filtersystemprojct && data && data.agentServiceEventsPrice){
    const datetimerang = JSON.parse(filtersystemprojct.other).livePurchase.paramarTime
    if(datetimerang){
      const arratime = datetimerang.split('&').map((e:any)=>e.replace(' ', '')).map((e:any)=>({start: moment(e.split('-')[0],'HH:mm'), end: moment(e.split('-')[1],'HH:mm')}));
      const today = moment()
      
      let i =0;
    filterData = data.agentServiceEventsPrice.filter((item: any) => {
      const startdate = moment(item.startDateTime, 'YYYY-MM-DDTHH:mm:ss').set({
          year: today.year(),
          month: today.month(),
          date: today.date()
      });
      const enddate = moment(item.endDateTime, 'YYYY-MM-DDTHH:mm:ss').set({
        year: today.year(),
        month: today.month(),
        date: today.date()
    });
     i++
      const ti = arratime.find((e:any)=>{
        return startdate.isSameOrAfter(e.start) && enddate.isSameOrBefore(e.end)
      });
      return ti !== undefined;
    })

  } }

return  (

       <>
       {parmargettickettype  ?
       <TicketDetails data={parmargettickettype}  parmargetticketevent={parmargetticketevent} 
       onClose={()=>{dispatch(updateStateOfPurchaseLiveSlice({key: 'parmargettickettype', data: undefined}))}} />: null} 
   <Form
      name="filterdaterangeticketforproduct"
      layout={'inline'}
      onFinish={onFormLayoutChange}
      className="login-form"
      style={{ maxWidth: 'none', padding: '5px' }}

    > 
      <Form.Item name="bookingDate" label="Booking Date" required>
        <DatePicker placeholder='Booking Date' />
      </Form.Item>



      <Form.Item>
        <Button type="primary" htmlType="submit" 
          className="login-form-button" loading={parmargettimeslotticketloading}>
            <SearchOutlined />
          Search tickets
        </Button>
      </Form.Item>
      {/* <Form.Item >
        <Button  ><ClearOutlined /> Clear Filter</Button>
      </Form.Item> */}
    </Form>
    <Space> Select System Project  <Radio.Group
    value={internalProductId}
    onChange={(e)=>setInternalProductId(e.target.value)}
    options={internalProduct?.map((ee:any)=>({value: ee.id, label: ee.masterValue }))}
  /></Space>

    <ParamarCardSummary parmargettimeslotticketloading={parmargettimeslotticketloading} 
      filterData={filterData}
      parmargettickettypeloading={parmargettickettypeloading}
      parmargettimeslotticketarg={parmargettimeslotticketarg}
      productid={productid}
      handelProductId={handelProductId} />
{/* 
   <List
      loading={parmargettimeslotticketloading}
    itemLayout="vertical"
    size="large"
    dataSource={data.agentServiceEventsPrice || []}
    renderItem={(item:any) => (
      
      <List.Item
        key={item.title}
      >
          <Descriptions
          bordered
          column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        > 
         <Descriptions.Item label="Reservation"> <Button type='primary' disabled={+item.available <= 0 || (parmargettickettypeloading && parmargettickettypeloading.eventID !== item.eventID)} 
        loading={parmargettickettypeloading && parmargettickettypeloading.eventID === item.eventID}
        onClick={()=>handelProductId({...parmargettimeslotticketarg,
        "eventTypeId": item.eventTypeID,
        "eventId": item.eventID,
        "status": item.status,
        "available": item.available,
        "resourceId": item.resourceID,
        "eventName": item.eventName,
        "startDateTime": item.startDateTime,
        "endDateTime": item.endDateTime, ...item})}>View Tickets Detail</Button></Descriptions.Item>
       
        <Descriptions.Item label="EventName">{item.eventName}</Descriptions.Item>
        <Descriptions.Item label="StartDateTime">{item.startDateTime}</Descriptions.Item>
        <Descriptions.Item label="EndDateTime">{item.endDateTime}</Descriptions.Item>
        <Descriptions.Item label="Available ticket count">{item.available}</Descriptions.Item>
        <Descriptions.Item label="Adult Price">{item.adultPrice}</Descriptions.Item>
        <Descriptions.Item label="Child Price">{item.childPrice}</Descriptions.Item>
        <Descriptions.Item label="resourceID">{item.resourceID}</Descriptions.Item>
        <Descriptions.Item label="status">{item.status}</Descriptions.Item>
          </Descriptions>
      </List.Item>
    )}
  />  */}
  </>);
}



export const ParmarPurchaseProductList = () => {
  const { parmarproductlistloading, parmarproductlist, parmargetagent,parmargetagentloading } = useSelector((state: any) => state.livepurchase);
  const { productid } = useParams<{ productid?: string }>();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handelProductId = (productId: string) => {
    navigate(`/home/ticketinventory/livepurchase/parmar/${productId || ''}`);
  };
  let data = ((parmarproductlist || {}).attractionList  || [])
  const { masterSelectValuesLoading, masterSelectValues } = useSelector((state: any) => state.master)
  const productMapping = masterSelectValues['Trip Ticket Inventory']?.reduce((arrg:any, e:any)=>{
    if(e.other) {
    const extra = JSON.parse(e.other);
    if(!arrg[extra?.livePurchase?.parmarattractionId]) {
      arrg[extra.livePurchase?.parmarattractionId]= [];
    }
    arrg[extra.livePurchase?.parmarattractionId].push(e);
  }
    return arrg
  }, {}) || {}

  useEffect(()=>{
    dispatch(parmarGetProducts(''));  
    dispatch(parmarGetAgentDetails(''));  
    

},[])

data = data.slice()
data.sort((a:any, b:any)=>{
if(productMapping[a.attractionId+''] && !productMapping[b.attractionId+'']) return -1;
if(!productMapping[a.attractionId+''] && productMapping[b.attractionId+'']) return 1;
return 0;
})

return  (

       <>
       <Breadcrumb
        items={[
      {
        title: 'Product List',
        onClick: () => navigate(`/home/ticketinventory/livepurchase/parmar`),
      },
      {
        title: 'Product Tickets',
      },
    ]} />
     <BalanceNCredit parmargetagent={parmargetagent || {}} parmargetagentloading={parmargetagentloading} /> 
    <br />
    {productid ? <ParmarPurchaseProductDeatil />:
      <List
      loading={parmarproductlistloading}
    itemLayout="vertical"
    size="large"
    loadMore
    dataSource={data}
    renderItem={(item:any) => {
       const typelist = item.ticketTypeList.filter((e:any)=>e.apiTicket)
      return typelist.length>0 ? 
      <List.Item key={item.attractionName}> {typelist.filter((e:any)=>e.apiTicket).map((initem:any)=>
        <Descriptions
          bordered
          title={` (ID: ${item.attractionId}, ticketTypeId: ${initem.ticketTypeId}) ${item.attractionName} (${initem.ticketTypeName})`}
          column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
          >
          <Descriptions.Item label="adultPriceStartingFrom">{initem.adultPriceStartingFrom}</Descriptions.Item>
          <Descriptions.Item label="childPriceStartingFrom">{initem.childPriceStartingFrom}</Descriptions.Item>
          <Descriptions.Item label="priceStartingFrom">{initem.priceStartingFrom}</Descriptions.Item>
          <Descriptions.Item label="apiCode">{initem.apiCode}</Descriptions.Item>
          <Descriptions.Item label="apiTicket">{initem.apiTicket+""}</Descriptions.Item>
          <Descriptions.Item ><Button type='primary' disabled={ !productMapping[item.attractionId+''] || !initem.apiTicket} onClick={()=>handelProductId(item.attractionId+','+initem.ticketTypeId )}>View Available tickets</Button></Descriptions.Item>
          </Descriptions>)}
      </List.Item> : <> </>
    }}
  /> }  
  </>);
}

