import { combineReducers, configureStore } from '@reduxjs/toolkit'
import  login from './component/security/slice-login'
import commonupload from './component/common/slice-upload'
import ticketInventory from './component/ticketinventory/slice-ticketInventory'
import livepurchase from './component/ticketinventory/livepurchase/slice-livepurchase'
import master from './component/common/slice-master';
import datatable from './component/common/slice-datatable'
import itineraryquotation from './component/itinerary/slice-itineraryquotation'
import { reducers } from './moduleconfiguration';



const rootReducer = combineReducers({
  login,
  commonupload,
  ticketInventory,
  master,
  datatable,
  itineraryquotation,
  livepurchase,
  httpapierros: (state:any={}, payload:any={type: 'NOTDHENDD', data: 1}) => {
  
  return state;
},
...reducers()
})

export const setupStore = (preloadedState?:any) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
