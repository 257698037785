import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Modal, Space, Switch, Table, Tag } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, Card } from 'antd';

import {
    ReloadOutlined,
    SearchOutlined,
    DownloadOutlined,
    ClearOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { downloadInventoryFile, downloadReport, downloadRequestExport } from './slice-ticketInventory';
import moment from 'moment';
import { MasterSelect } from '../common/MasterSelect';
import { DataTable } from '../common/DataTable';
import { formatDateInTimeZone, removeNullQueryParam } from '../utils/CommonFn';




const TicketInventoryFilter = ({onFilterChange, filters}:any) => {

    const {downloadRequestExportloading} = useSelector((state: any) => state.ticketInventory);
    const dispatch = useDispatch();
    const [state, setState] = useState();
    const [form] = Form.useForm();
    const onFormLayoutChange = (values: any) => {
        onFilterChange(values);
    };
  
    return (
      <Form
        name="downloadrequesttiproductfilterform"
        layout={'inline'}
        onValuesChange={e=>{
          setState(e)
        }}
        onFinish={onFormLayoutChange}
        form={form}
        initialValues={{ ...filters }}
        style={{ maxWidth: 'none', padding: '5px' }}
  
      >     
      <Space>
      <Form.Item
          name="invoice"
          label="Invoice No"
        >
          <Input placeholder='invoice no' />
        </Form.Item>
        <Button type="primary" htmlType="submit" onClick={()=>form.submit()}> Filter </Button>
        <Button style={{marginLeft:'5px'}} onClick={()=>{form.resetFields();form.submit()}}> Clear </Button>
        <Form.Item name="date" label="Download Date">
            <DatePicker placeholder='Select Date' disabledDate={(current)=>current && current > moment()}  />
        </Form.Item>

        <Button type="primary" loading={downloadRequestExportloading} style={{marginLeft:'5px'}} disabled={!form.getFieldValue('date')} onClick={()=>{
          
            let da = form.getFieldValue('date');
            let date = moment(da.format('YYYY-MM-DD'), 'YYYY-MM-DD').add(moment().utcOffset()+10, 'minutes').toISOString();
            
             dispatch(downloadRequestExport({date: date}));
        }}> Export </Button>
        </Space>
      </Form>
    );
  };



const columns: any = [
    {
        title: '#',
        dataIndex: 'id',
    },
    {
        title: 'Download Request By',
        dataIndex: 'creatorName',
    },
    {
        title: 'Product',
        dataIndex: 'productName',
    },
    
    {
        title: 'Customer',
        dataIndex: 'customer',
    },
    {
        title: 'Ref invoice No',
        dataIndex: 'invoice',
    },
    {
        title: 'Status',
        render: (e: any) => e.bucketObject && e.bucketObject.length > 10 ? 'Ready' : 'Creating Pdf',
    },
    // {
    //     title: 'Download Count',
    //     dataIndex: 'count',
    // },
    {
        title: 'ticketcount',
        render: (e: any) => (e.ticketcount || 0),
    },
    {
        title: 'Request Date',
        render: (e: any) => formatDateInTimeZone(e.created),
        
    },
    {
        title: 'Download',        
    },
    // {
    //     title: 'View Detail',
    //     render: () => <Button>Detail</Button>
    // },
    // {
    //     title: 'Download History',
    //     render: (e: any) => e.count === 0 ? <></> : <Button>Download History</Button>
    // },
];




export const TicketInventoryDownload = () => {
    const { downloadRequestData } = useSelector((state: any) => state.ticketInventory);
    const dispatch = useDispatch()
    const [filters, setFilters] = useState({} as any);
    const [loadingDownload, setLoadingDownload] = useState({} as any)
    const datatableref = useRef();
    
    useEffect(()=>{
        
        if(datatableref.current)
            (datatableref.current as any).refresh()
    },[filters])
    columns[columns.length - 1].render = (el: any) => el.bucketObject && el.bucketObject.length > 10 ? <Button loading={loadingDownload[el.id]} onClick={() => { setLoadingDownload((e: any) => ({ ...e, [el.id]: true })); setTimeout(() => setLoadingDownload((e: any) => ({ ...e, [el.id]: false })), 1500); dispatch(downloadInventoryFile(el)); }}> <DownloadOutlined /> Download</Button> : null;

    return (<> 
            
        
        <DataTable
            ref={datatableref}
            title="Download List (Sorted By: Download Date)" 
            extra={<TicketInventoryFilter onFilterChange={(v:any)=>setFilters(v)} filter={filters} />}
            key="id" columns={columns} 
            componentuniquekey="datatabledownloadlist" 
            lazyPagination={true}
            buildApiUrl={(pageNumber, pagesize)=>removeNullQueryParam(`downloadlist?pageSize=${pagesize}&pageNo=${pageNumber}&invoice=${filters.invoice}`)} /> 
        </>);
}





