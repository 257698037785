import React, { useEffect, useRef, useState } from 'react';
import { Card, DatePicker, Modal, Space, Descriptions, Table, Rate, Checkbox, Divider, Row, Col, InputNumber } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, TimePicker,  } from 'antd';

import {
    ReloadOutlined,
    SearchOutlined,
    
    CloseOutlined,
    DeleteOutlined,
    ClearOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { itineraryQuotationProductSearch, addProductToQuotation, itineraryFetchCustomerByEmail, updateDraftQuotation, itineraryFetchHotelByName, addHotelToQuotation, itinerarySaveHotel, itinerarySaveQuotationDetail, itinerarySaveCustomerDetail, updateDraftQuotationUiState, resetquota, itineraryDeleteHotel, itineraryDeleteProduct, saveProductOfIntenotryproduct }
 from '../slice-itineraryquotation';
import { key } from 'localforage';
import { useForm } from 'antd/es/form/Form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { stat } from 'fs';
import { SearchProductModal } from './ProductModal';
import { TableDragableRow } from '../DrageRowTable';
import { getTimeDropdownOptionsValue } from '../../utils/CommonFn';




const timepicketoption = getTimeDropdownOptionsValue();



export const QTourList = () => {
    const {quotation, saveQuotationdetailloading, uotationuistate}= useSelector((state:any)=>state.itineraryquotation);
    const uistate = uotationuistate;
    const dispatch = useDispatch();
    const ref = useRef<any>();
    const refin = useRef<any>();
    const [form] = useForm();
    var rooms = +(quotation.quotationInfo.rooms || 0);
    var extrabed = +(quotation.quotationInfo.extrabed || 0);


    let totalseat = +quotation.quotationInfo.adult + +quotation.quotationInfo.child

    const setuiState = (state:any) => {
        dispatch(updateDraftQuotationUiState(state))
      }


      const conditionFieldDiv = (field:string, row: any, component : any, valuefn?:any, eventfire?:any, filedPrefix:string='') => {
        return uistate.editId === (filedPrefix+'-'+row.id +'-'+ field) ? 
        <Form.Item name={row.id +'-'+ field}>
                        {React.cloneElement(component, {ref: refin, className:'editableonhover'})}
                      </Form.Item> 
                      : <div onClick={()=>{
          setuiState({...uistate, editId: (filedPrefix+'-'+row.id +'-'+ field) }); 
          setTimeout(()=> {
            form.setFieldValue((row.id +'-'+ field), valuefn? valuefn(row[field]): row[field]);
            eventfire? eventfire(refin):refin.current.focus() 
            
            },100)}
        } className='editableonhover'>
          {row[field]}
          </div>
      }  

      const updateEditedPrdouctData = (value:any) => {
        const uniquess = uistate.editId.split('-')
                const id = uniquess[1];
                const field = uniquess[2];
                  const n = quotation.products.map((el:any)=>{
                  if(el.id+'' === id){
                     return  {...el, [field]:  value}
                  } 
                  return {...el};
                });
              dispatch(updateDraftQuotation({...quotation, products: n}))
                setuiState({...uistate, editId: "0" });
      }


    return <>
     {uistate.serachProductPopup? <SearchProductModal open={uistate.serachProductPopup} onCancel={()=>setuiState({...uistate, serachProductPopup: false })}/> : null }
       
    <Card loading={saveQuotationdetailloading} title="Tour Info: " extra={<Space wrap>
        Tour Inlcude <Checkbox checked={uistate.tourenable} onChange={e=>setuiState({...uistate, tourenable: !uistate.tourenable})}/>
       
      <Divider dashed type='vertical' />
       <Button disabled={ !uistate.tourenable } onClick={e=>setuiState({...uistate, serachProductPopup: true })}>Add Product</Button></Space>}>
        {
          uistate.tourenable ?  <>
        <Form name='productform' form={form}
              onBlur={(e)=>{

                const filename =  e.target.getAttribute('id')
                
                if(filename && !uistate.editId.endsWith('date') && !uistate.editId.endsWith('time') && !uistate.editId.endsWith('transferType')) {
                  updateEditedPrdouctData(e.target.value)
                }
              }}
              onValuesChange={(value)=>{
                const uniquess = uistate.editId.split('-')
                const id = uniquess[1];
                const field = uniquess[2];
                const key = id+'-'+field;
                if(uistate.editId.endsWith('date')) {
                  updateEditedPrdouctData(value[key].format('DD-MM-YYYY'))
                } else if(uistate.editId.endsWith('time') || uistate.editId.endsWith('transferType')) {
                  updateEditedPrdouctData(value[key])
                }
              }}
        
        >

        
       <TableDragableRow ref={ref} data={quotation.products} rowKey={'id'} 
       
       onDragFinish={(newdaa:any)=>dispatch(updateDraftQuotation({...quotation, products: newdaa}))}
       >
              <Table.Column title="Product Name" render={(row:any)=>{
                  return <> {row.productName}
                  { form.setFieldValue((row.id +'-description'), row.description)}
                      <Form.Item name={''+row.id +'-description'}>
                          <Input onFocus={()=>setuiState({...uistate, editId: (''+'-'+row.id +'-description') }) }/>
                      </Form.Item>
                  </>
              }} />

              <Table.Column title="Transfer Type"
              render={(a, e:any)=>conditionFieldDiv('transferType',  e, <Select disabled={ !uistate.tourenable } defaultValue=""
              style={{ width: 120 }}
             options={[
               { value: 'Private', label: 'Private' },
               { value: 'Shared', label: 'Shared' },
               { value: 'Without Transfer', label: 'Without Transfer' },
             ]} />, (v:any)=>v, (eventfire:any)=>{}) } />
              
              <Table.Column title="Tour Date"
              render={(a, e:any)=>
              conditionFieldDiv('date',  e, <DatePicker  minDate={dayjs(quotation.quotationInfo.from, 'DD-MM-YYYY')} maxDate={dayjs(quotation.quotationInfo.to, 'DD-MM-YYYY')}    allowClear={false} format={'DD-MM-YYYY'}/>, (v:any)=>v?dayjs(v, 'DD-MM-YYYY'): dayjs(), (eventfire:any)=>eventfire.current.nativeElement.click()) } />
              <Table.Column title="Time Slot" render={(a, e:any)=>conditionFieldDiv('time',  e, <Select options={timepicketoption}/>, (v:any)=>v) } />
              
              {!uistate.isFlatPrice ? <>
              <Table.Column title="Price"  render={(a, e:any)=> e.transferType !== 'Private' ?
                conditionFieldDiv('price',  e, <Input />) : 'N/A' } />
              <Table.Column title="Total" render={(a, e:any)=>e.transferType === 'Private' ?
                conditionFieldDiv('totalprice',  e, <Input />) : totalseat * e.price}/>
                </> :null}
              <Table.Column title="Action" render={(el:any)=><Button onClick={()=>{
                    const quotationv = {...quotation}
                    quotationv.products = quotationv.products.filter((e:any)=>e.id !== el.id)
                    dispatch(updateDraftQuotation(quotationv));
                }}><CloseOutlined /></Button>} />
            

             
             </TableDragableRow> 
             </Form>

             
             </>
             : null
            }
       </Card>
    </>

}